<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="选择客户" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
              mode="multiple"
              allowClear
              show-search
              :filter-option="filterOption"
              v-decorator="['customer_ids', {rules: [{required: true, message: '请选择客户'}]}]"
              placeholder="请选择客户"
          >
            <a-select-option v-for="item in customerList" :value="item.customer_id">
              {{ item.customer_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/data/waybill'
import * as customerApi from '@/api/data/customer'
import _ from "lodash";

export default {
  components: {},
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 5
      },
      // 输入框布局属性
      wrapperCol: {
        span: 18
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      customerList: []
    }
  },
  methods: {
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    /**
     * 显示对话框
     */
    open(record) {
      this.mode = "add"
      this.title = '所属客户'
      this.visible = true
      this.record = record
      this.getCustomer()
      this.setFieldsValue()
    },

    /**
     * 获取客户
     */
    async getCustomer() {
      const {data: {list}} = await customerApi.getSelectList()
      this.customerList = list
    },
    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['customer_ids'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = Api.saveCustomer({waybillId: this.record['waybill_id'], form: values})
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>


<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
