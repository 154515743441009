<template>
  <a-modal
      :title="title"
      :width="920"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :centered="true"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="运输路线" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-button type="primary" size="small" @click="handleAddWaybill">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td">起始地点</div>
              <div class="td">到达终点</div>
              <div class="td" style="width: 20%">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in waybillList">
              <div class="td">
                <a-form-item>
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      placeholder="请选择起始地点"
                      v-decorator="['address.'+index+'.start_address_id', {rules: [{required: true, message: '请选择起始地点'}]}]"
                  >
                    <a-select-option v-for="item in addressList" :value="item.address_id">
                      {{ item.address_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

              </div>
              <div class="td">
                <a-form-item>
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      placeholder="请选择到达终点"
                      v-decorator="['address.'+index+'.end_address_id', {rules: [{required: true, message: '请选择到达终点'}]}]"
                  >
                    <a-select-option v-for="item in addressList" :value="item.address_id">
                      {{ item.address_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="td" style="width: 20%">
                <a style="color: red" @click="handleDel(index)">删除</a>
              </div>
            </div>
          </div>

        </a-form-item>
        <a-form-item v-if="algorithm==1" label="提成设置" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-button type="primary" size="small" @click="handleAddItem('commissions')">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td" style="width: 25%">装货品名</div>
              <div class="td" style="width: 25%">驾驶员提成</div>
              <div class="td" style="width: 25%">押运员提成</div>
              <div class="td" style="width: 25%">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in commissions">
              <div class="td" style="width: 25%">
                <a-form-item>
                  <a-select
                      style="width: 100%"
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.goods_id"
                      placeholder="请选择装货品名"
                  >
                    <a-select-option v-for="item in goods_list" :value="item.goods_id">
                      {{ item.goods_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="td" style="width: 25%">
                <a-input-number
                    style="width: 100%"
                    allowClear
                    placeholder="请输入驾驶员提成金额"
                    v-model="item.driver_commission"
                />
              </div>
              <div class="td" style="width: 25%">
                <a-input-number
                    style="width: 100%"
                    allowClear
                    placeholder="请输入押运员提成金额"
                    v-model="item.supercargo_commission"
                />
              </div>
              <div class="td" style="width: 25%">
                <a style="color: red" @click="handleDeleteItem('commissions',index)">删除</a>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="含税价格" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-button type="primary" size="small" @click="handleAddPirce">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td" style="width: 21.25%">装货品名</div>
              <div class="td" style="width: 21.25%">所属客户</div>
              <div class="td" style="width: 21.25%">每吨价格</div>
              <div class="td" style="width: 21.25%">每车价格</div>
              <div class="td" style="width: 15%">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in price_list">
              <div class="td" style="width: 21.25%">
                <a-form-item>
                  <a-select
                      style="width: 100%"
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.goods_id"
                      placeholder="请选择装货品名"
                  >
                    <a-select-option v-for="item in goods_list" :value="item.goods_id">
                      {{ item.goods_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="td" style="width: 21.25%">
                <a-form-item>
                  <a-select
                      style="width: 100%"
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.customer_id"
                      placeholder="请选择客户"
                  >
                    <a-select-option v-for="item in customer_list" :value="item.customer_id">
                      {{ item.customer_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="td" style="width: 21.25%">
                <a-input-number
                    style="width: 100%;"
                    allowClear
                    placeholder="请输入每吨价格"
                    v-model="item.price"
                />
              </div>
              <div class="td" style="width: 21.25%">
                <a-input-number
                    style="width: 100%;"
                    allowClear
                    placeholder="请输入每车价格"
                    v-model="item.price_v"
                />
              </div>
              <div class="td" style="width: 15%">
                <a style="color: red" @click="handlePriceDel('price_list',index)">删除</a>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="不含税价格" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-button type="primary" size="small" @click="handleAddNotaxPirce">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td" style="width: 21.25%">装货品名</div>
              <div class="td" style="width: 21.25%">所属客户</div>
              <div class="td" style="width: 21.25%">每吨价格</div>
              <div class="td" style="width: 21.25%">每车价格</div>
              <div class="td" style="width: 15%">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in notax_price_list">
              <div class="td" style="width: 21.25%">
                <a-form-item>
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.goods_id"
                      placeholder="请选择装货品名"
                  >
                    <a-select-option v-for="item in goods_list" :value="item.goods_id">
                      {{ item.goods_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="td" style="width: 21.25%">
                <a-form-item>
                  <a-select
                      style="width: 100%"
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.customer_id"
                      placeholder="请选择客户"
                  >
                    <a-select-option v-for="item in customer_list" :value="item.customer_id">
                      {{ item.customer_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="td" style="width: 21.25%">
                <a-input-number
                    style="width: 100%;"
                    allowClear
                    placeholder="请输入每吨价格"
                    v-model="item.price"
                />
              </div>
              <div class="td" style="width: 21.25%">
                <a-input-number
                    style="width: 100%;"
                    allowClear
                    placeholder="请输入每车价格"
                    v-model="item.price_v"
                />
              </div>
              <div class="td" style="width: 15%">
                <a style="color: red" @click="handlePriceDel('notax_price_list',index)">删除</a>
              </div>
            </div>
          </div>


        </a-form-item>
        <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['status',{initialValue:0, rules: [{required: true}]}]"
          >
            <a-radio :value="0">启用</a-radio>
            <a-radio :value="1">停用</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/data/waybill'
import _ from "lodash";
import {isEmpty} from "@/utils/util";
import * as settingApi from "@/api/setting/store";

export default {
  components: {},
  props: ['addressList', 'goods_list', 'customer_list'],
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 2
      },
      // 输入框布局属性
      wrapperCol: {
        span: 22
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      // 运单信息
      waybillList: [
        {}
      ],

      zhpmList: [{}],

      // 含税价格
      price_list: [{
        price_type: 0, price: '', price_v: ''
      }],
      // 不含税价格
      notax_price_list: [{
        price_type: 1, price: '', price_v: ''
      }],

      // 提成设置
      commissions: [
        {}
      ],
      algorithm: ''
    }
  },
  methods: {
    getSettingDetail() {

      this.isLoading = true
      settingApi.detail('salary')
          .then(result => {
            if (!isEmpty(result.data.values.algorithm)) {
              this.algorithm = result.data.values.algorithm
            } else {
              this.$nextTick(() => {
                const that = this
                this.$confirm({
                  title: '您当前还未设置工资算法，请设置工资算法后再试！',
                  okText: '前往设置',
                  onOk() {
                    that.$router.push('/setting/personnelSalary')
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                  class: 'test',
                });
              })
            }
          })
          .finally(result => {
            this.isLoading = false
          })
    },

    handleAddItem(field) {
      this[field].push({})
    },

    handleDeleteItem(field, index) {
      if (this[field].length <= 1) {
        this.$message.error('至少保留一项！')
        return
      }
      this[field].splice(index, 1)
    },

    handleAddPirce() {
      this.price_list.push({
        price_type: 0, price: '', price_v: ''
      })
    },

    handleAddNotaxPirce() {
      this.notax_price_list.push({
        price_type: 1, price: '', price_v: ''
      })
    },

    handlePriceDel(field, index) {
      if (this[field].length <= 1) {
        this.$message.error('至少保留一项地址！')
        return
      }
      this[field].splice(index, 1)
    },


    handleDel(index) {
      if (this.waybillList.length <= 1) {
        this.$message.error('至少保留一项地址！')
        return
      }
      this.waybillList.splice(index, 1)
    },
    handleZhpmDel(index) {
      if (this.zhpmList.length <= 1) {
        this.$message.error('至少保留一项！')
        return
      }
      this.zhpmList.splice(index, 1)
    },
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '添加路线'
      this.visible = true
      this.record = {}
      this.getSettingDetail()
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑路线'
      this.visible = true
      this.record = record
      this.setFieldsValue()
      this.getSettingDetail()

    },

    handleAddWaybill() {
      this.waybillList.push({})
    },
    handleAddZhpm() {
      this.zhpmList.push({})
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['goods_id', 'price', 'price_v', 'notax_price', 'notax_price_v', 'driver_commission', 'supercargo_commission', 'status'])
        setFieldsValue(data)

        var list = []
        this.record.address_list.forEach((item, index) => {
          list['address.' + index + '.start_address_id'] = item.start_address_id
          list['address.' + index + '.end_address_id'] = item.end_address_id
        })
        this.record.goods_list.forEach((item, index) => {
          list['goods.' + index + '.goods_id'] = item.goods_id
          list['goods.' + index + '.goods_id'] = item.goods_id
        })
        this.waybillList = this.record.address_list
        this.zhpmList = this.record.goods_list

        if (!isEmpty(this.record.price_list)) {
          this.price_list = this.record.price_list
        }
        if (!isEmpty(this.record.notax_price_list)) {
          this.notax_price_list = this.record.notax_price_list
        }

        if (!isEmpty(this.record.commission_list)) {
          this.commissions = this.record.commission_list
        } else {
          this.commissions = [{}]
        }

        this.$nextTick(() => {
          const data2 = _.pick(list, Object.keys(list))
          setFieldsValue(data2)
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      values.price_list = this.price_list
      values.notax_price_list = this.notax_price_list
      values.commissions = this.commissions

      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({waybillId: this.record['waybill_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>


<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
